.job-container {
  
  margin: 10px;
  padding: 10px;
  width:30%;
  height: 150px;
  border-radius: 10px;
  background-color: white;
  transition: 0.5s;
  cursor: pointer;

}

.job-container:hover {
  width: 33%;
  height: 180px;
  /* transform: translate(-5%, 0); */
}
