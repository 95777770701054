.blue-shadow-checkbox .ant-checkbox-inner {
  border-color: blue; /* Change the checkbox border color to blue */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Add a shadow to the checkbox */
}

.blue-shadow-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: blue; /* Change the background color when checked */
  border-color: blue; /* Ensure the border color remains blue when checked */
}

.blue-shadow-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: blue; /* Change the border color on hover and focus */
}
