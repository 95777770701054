.landing-title {
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
    line-height: 30.24px;
    text-align: center;
    margin-bottom: 8px;

    @media (min-width: 768px) {
        font-size: 32px;
    }
}

.landing-subtitle {
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.68px;
    text-align: center;

    /* Classes on bigger screen size */
    @media (min-width: 768px) {
        font-size: 20px;
    }

    a {
        color: inherit; /* Inherit the parent's color */
        font-weight: 600;
        text-decoration: underline;
    }
}

.landing-lists {
    text-align: left;
    ul {
        padding: 0 0 0 18px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;

        span {
            font-weight: 700;
        }

        li:not(:last-child) {
            padding-bottom: 16px;
        }

        li {
            list-style-type: disc;
        }
    }

    p {
        color: #333333;
        font-weight: 700;
        font-size: 16px;
        line-height: 24.2px;
        margin-bottom: 8px;
    }

    /* Classes on bigger screen size */
    @media (min-width: 768px) {
        p {
            font-size: 18px;
            line-height: 30.24px;
        }

        ul {
            font-size: 18px;
            line-height: 22.68px;

            li:not(:last-child) {
                padding-bottom: 12px;
            }
        }
    }
}

.my-custom-card .ant-card-body {
    padding: 12px 12px 0px 12px;
}
