.custom-disabled-button {
    &.ant-btn-variant-solid:disabled {
        background: #84a3dd !important;
        color: #ffffff !important;
    }
}

.custom-postlogin-card {
    .ant-card-body {
        padding: 32px 24px;
    }

    .ant-form-item {
        margin-bottom: 0;
    }
}
