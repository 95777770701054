.custom-update-mobile-no-form {
    .ant-form-item {
        margin-bottom: 24px !important;
    }
}

.custom-disabled-button {
    &.ant-btn-variant-solid:disabled {
        background: #84a3dd !important;
        color: #ffffff !important;
    }
}
